const Footer = () => {
  return (
    <footer className="flex py-8 justify-center items-center">
      <span className="text-xs font-light lg:mt-8 flex justify-center items-center grow">
        © {new Date().getFullYear()} Eloïc Ah-hing
      </span>
    </footer>
  );
};

export default Footer;
