import headerNavLinks from '../../data/headerNavLinks';
import Link from 'next/link';
import ThemeSwitch from './ThemeSwitch';
import { useRouter } from 'next/router';

const Header = () => {
  const router = useRouter();
  const handleLocaleChange = (event: any) => {
    const value = event.target.value;

    router.push(router.route, router.asPath, {
      locale: value
    });
  };

  return (
    <header className="flex items-center justify-end py-8">
      <div className="flex items-center text-base leading-5">
        <div className="sm:block">
          {headerNavLinks.map(link => (
            <Link
              key={link.title}
              href={link.href}
              className="p-1 font-medium text-gray-900 dark:text-gray-100 sm:p-4"
            >
              {link.title}
            </Link>
          ))}
        </div>
        <ThemeSwitch />
        <select
          className="ml-4"
          onChange={handleLocaleChange}
          value={router.locale}
        >
          <option value="fr">🇫🇷</option>
          <option value="en">🇬🇧</option>
          <option value="zh-CN">🇨🇳</option>
        </select>
        {/* <MobileNav /> */}
      </div>
    </header>
  );
};

export default Header;
