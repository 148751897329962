import { ReactNode } from 'react';
// import Footer from './Footer';
// import Link from './Link';
import Footer from './layouts/Footer';
import Header from '../components/layouts/Header';
// import MobileNav from './MobileNav';
// import SectionContainer from './SectionContainer';
// import ThemeSwitch from './ThemeSwitch';

interface Props {
  children: ReactNode;
}

const LayoutWrapper = ({ children }: Props) => {
  return (
    <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
      <div className="flex h-screen flex-col justify-between">
        <Header />
        <main className="mb-auto">{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default LayoutWrapper;
